import { Link } from "gatsby"
import React, { useState } from "react"
import Modal from "react-modal"

import { Container, Button } from "../../shared"
import { Title } from "../Title/title"

import "./contact.css"
import ContactUsForm from "./ContactUsForm"

// const customStyles = {
//   content: {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     background: 'rgba(255, 255, 255, 0.75)',
//     overflow: 'auto',
//     borderRadius: '4px',
//     outline: 'none',
//     padding: '20px',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//     width: '30%',
//     display: 'grid',
//     zIndex: '111111111'
//   },
// }

const Contact = () => {

  const [modalIsOpen, setIsOpen] = useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <div id="contact" className="contact-area">
      <Container>
      {/* <Title title="" social height="320px" /> */}
        <div className="contact">
          <div className="contact-status">
            <h2 style={{ color: "#d8624c", textAlign: "center" }}>
              Get In Touch
            </h2>
            <p>
              Interested in knowing more about me? It's time to discuss about
              your recent projects updating or brand new project and I am ready
              to help you make smart decisions that best meet your needs. I
              would love to hear from you.
            </p>
            <div style={{ textAlign: "center", marginTop: '25px' }}>
              <a className="sayHi-btn" onClick={openModal}>
                Say Hi
              </a>

              <ContactUsForm modalIsOpen={modalIsOpen} closeModal={closeModal}></ContactUsForm>
              {/* <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
              >

                <ContactUsForm closeModal={closeModal}></ContactUsForm>


              </Modal> */}
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export { Contact }
