import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { blogs } from "../../data"
import { Button } from "../shared"
import { Container } from "../shared"

const Blog = () => {
  const [featuredBlogs, setFeaturedBlogs] = useState([])
  const [allBlogs, setAllBlogs] = useState([])

  // Loading all Blogs and update state allBlogs
  useEffect(() => {
    let allBlogsArr = []

    for (let el of Object.values(blogs.All)) {
      allBlogsArr.push(...el)
    }
    setAllBlogs(allBlogsArr)
  }, [])

  // When allBlogs is updated, featured protfolios are filtered and update state
  useEffect(() => {
    const filteredBlogs = allBlogs
      .filter(portfolio => portfolio.type === "featured")
      .slice(0, 3)

    setFeaturedBlogs(filteredBlogs)
  }, [allBlogs])

  console.log(featuredBlogs)
  return (
    <div id="blog" className="blog-area">
      <Container>
        <div className="title right" style={{ height: "160px" }}>
          <p>Blogs</p>
        </div>

        <div className="blogs">
          <div className="blog-title">
            <div>
              <h2>
                {" "}
                <span style={{ color: "#d8624c" }}>Fetured</span> Blogs
              </h2>
            </div>

            <div style={{ textAlign: "right" }}>
              <Link className="all-link" to="/blog">
                <Button title="All Blogs" />
              </Link>
            </div>
          </div>

          <div className="blog-items">
            {featuredBlogs &&
              featuredBlogs.map((blog, idx) => (
                <div key={idx} className="blog">
                  <a
                    className="blog-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={blog.source && blog.source}
                  >
                    <div className="blog-img mouseEvents">
                      <img alt={blog.name} src={blog.image} />
                    </div>
                    <div className="details">
                      <h4>{blog.name}</h4>
                      <p>{blog.description}</p>
                      <div className="links">
                        <ul
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {blog.date && <li>{blog.date}</li>}
                          {blog.source && (
                            <li>
                              <a
                                style={{ textDecoration: "none" }}
                                target="_blank"
                                rel="noopener noreferrer"
                                href={blog.source}
                              >
                                Read More...
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                      <ul className="blog-tools">
                        {blog.tools &&
                          blog.tools.map(tool => <li key={tool}>{tool}</li>)}
                      </ul>
                    </div>
                  </a>
                </div>
              ))}
          </div>
        </div>
      </Container>
    </div>
  )
}

export { Blog }
