import React from "react"

import Typical from "react-typical"

import { Container, Title, Button } from "../shared"

import "./intro.css"

const Intro = () => {
  return (
    <div className="intro-area">
      <Title title="Follow Me" social height="320px" />
      <Container>
        <div className="intro">
          <div className="intro-text">
            <p>Hey! This is</p>
            <h1>Md Mostafa Al Mahmud</h1>
            <h3 style={{ marginBottom: "3%" }}>
              <span style={{ display: "inline-block", color: "white" }}>
                Hi, I am a
              </span>{" "}
              <Typical
                steps={[
                  "AWS Community Builder",
                  2000,
                  "JavaScript Lover",
                  2000,
                  "Typescript Player",
                  2000,
                  "Public Speaker",
                  2000,
                  "Problem solver",
                  2000,
                  "Full Stack Developer",
                  2000,
                  "Serverless Architect",
                  2000,
                ]}
                loop={Infinity}
                wrapper="span"
              />
            </h3>

            <div className="action">
              <Button
                link="https://drive.google.com/u/0/uc?id=1iyGLsJfu-imIgkdzgZq75pu9q3hNj6DW&export=download"
                target="__blank"
                bgColor="#d8624c"
                title="Get Resume"
              />
              <Button clickEvent link="#about" ml="15px" title="About Me" />
            </div>
          </div>
        </div>
        
      </Container>
    </div>
  )
}

export { Intro }
