import emailjs from "emailjs-com"
import React from "react"
import Modal from "react-modal"

const customStyles = {
    content: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      background: 'rgba(255, 255, 255, 0.75)',
      overflow: 'auto',
      borderRadius: '4px',
      outline: 'none',
      padding: '20px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '30%',
      display: 'grid',   
    },
    overlay: {zIndex: 50000}
  }


export default function ContactUsForm({ modalIsOpen, closeModal }) {
  function sendEmail(e) {
    e.preventDefault()

    emailjs
      .sendForm(
        "webmostafa",
        "template_e94rwjl",
        e.target,
        "user_tfbbQLj1stMt4S8jQAbKF"
      )
      .then(
        result => {
          console.log(result.text)
        },
        error => {
          console.log(error.text)
        }
      )

    closeModal()
    alert("Thank you for your interest. We will back to you soon.")
  }

  return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
          <div className="contact-form-area">
        <form className="contact-form" onSubmit={sendEmail}>
          <h2 align="center">Get In Touch</h2>
          <label>Name</label>
          <input type="text" name="user_name" placeholder="Your Name" />
          <label>Email</label>
          <input type="email" name="user_email" placeholder="Your Email" />
          <label>Message</label>
          <textarea name="message" placeholder="Your message" />
          <input
            type="submit"
            style={{ backgroundColor: "#d8624c", marginTop: "10px" }}
            value="Send"
          />
          <p>
            Sending to <i style={{ color: "#d8624c" }}>mostafa@mdmostafa.com</i>
          </p>
          <p>*Your information to us is safe and secure.</p>
        </form>
        </div>
      </Modal>
  )
}
