import React from "react"
import "./index.css"
import Particles from "react-particles-js"

import {
  Intro,
  Interests,
  Portfolio,
  About,
  Skill,
  Contact,
} from "../components"
import { Layout, SEO } from "../components/shared"
import { Blog } from "../components/Blog/Blog"

const IndexPage = (props) => {

  return (
  <>
    <div className="body-particles">
      <Layout locationPath={props.location.pathname}>
        <SEO title="Md Mostafa Al Mahmud" />
        <Intro />
        <About />
        <Skill />
        <Portfolio />
        <Blog />
        <Interests />
        <Contact />
      </Layout>
    </div>
    <div id="particles-js">
      <Particles
        params={{
          particles: {
            number: {
              value: 65,
            },
            size: {
              value: 3,
            },
          },
          interactivity: {
            events: {
              onhover: {
                enable: true,
                mode: "repulse",
              },
            },
          },
        }}
      />
    </div>
  </>
)}

export default IndexPage;
