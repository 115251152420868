import React from "react"

import { Container, Title, Button } from "../../shared"

import profile from "../../../assets/image/profile.png"

import "./about.css"

const About = () => {
  return (
    <div id="about" className="about-area">
      <Container>
        <Title side="right" title="About Me" />
        <div className="about">
          <div className="about-details">
            <p>
              Hello! This is Md Mostafa Al Mahmud, a JavaScript lover practicing
              building web, mobile, and desktop applications. I am passionate
              about serverless cloud technology building real-world solutions
              using Javascript, Typescript, React, Node, Express, MongoDB, and
              so on. Currently, I have been honored as an AWS Community Builder
              in the Serverless category from Amazon Web Services (AWS). Also, I
              had some speaking experiences on AWS best practices and technology
              learning.
            </p>
            <div className="about-action">
              <Button
                link="https://github.com/bdmostafa/bdmostafa/files/5460247/Md.Mostafa.Al.Mahmud.Resume.pdf"
                target="_blank"
                bgColor="#d8624c"
                title="Get Resume"
              />
              <Button clickEvent link="#skill" ml="15px" title="My Skills" />
            </div>
          </div>
          <div className="about-image">
            <div className="image">
              <img alt="profile" src={profile} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export { About }
