import React, { useEffect, useState } from "react"
import { FaGithub, FaExternalLinkAlt, FaCodepen } from "react-icons/fa"

import { Container } from "../shared/Container/container"

import { portfolios } from "../../data"

import "./portfolio.css"
import { Button } from "../shared"
import { Link } from "gatsby"

const Portfolio = () => {
  const [featuredPortfolios, setFeaturedPortfolios] = useState([])
  const [allPortfolios, setAllPortfolios] = useState([])

  // Loading all portfolios and update state allPortfolios
  useEffect(() => {
    let allPortfoliosArr = []

    for (let el of Object.values(portfolios.All)) {
      allPortfoliosArr.push(...el)
    }
    setAllPortfolios(allPortfoliosArr)
  }, [])

  // When allPortfolios is updated, featured protfolios are filtered and update state
  useEffect(() => {
    const filteredPortfolios = allPortfolios
      .filter(portfolio => portfolio.type === "featured")
      .slice(0, 6)

    setFeaturedPortfolios(filteredPortfolios)
  }, [allPortfolios])


  return (
    <div id="portfolio" className="portfolio-area">
      <Container>
        <div className="title right" style={{ height: "160px" }}>
          <p>Portfolio</p>
        </div>

        <div className="portfolios">
          <div className="portfolio-title">
            <div>
              <h2>
                {" "}
                <span style={{ color: "#d8624c" }}>Fetured</span> Portfolios
              </h2>
            </div>

            <div style={{ textAlign: "right" }}>
              <Link className="all-link" to="/portfolios">
                <Button title="All Portfolios" />
              </Link>
            </div>
          </div>
          <div className="portfolio-items portfolios-featured">
            {featuredPortfolios.map((portfolio, idx) => (
              <div key={idx} className="portfolio">
                <div className="portfolio-img mouseEvents">
                  <img alt={portfolio.name} src={portfolio.image} />
                </div>
                <div className="details">
                  <h4>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={portfolio.demo}
                    >
                      {portfolio.name}
                    </a>
                  </h4>
                  <p>{portfolio.description}</p>
                  <ul className="portfolio-tools">
                    {/* <span style={{ marginRight: "10px" }}>Technology:</span> */}
                    {portfolio.tools.map(tool => (
                      <li key={tool}>{tool}</li>
                    ))}
                  </ul>
                  <div className="links">
                    <ul>
                      {portfolio.source && (
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={portfolio.source}
                          >
                            <FaGithub />
                          </a>
                        </li>
                      )}
                      {portfolio.demo && (
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={portfolio.demo}
                          >
                            <FaExternalLinkAlt />
                          </a>
                        </li>
                      )}
                      {portfolio.codepen && (
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={portfolio.codepen}
                          >
                            <FaCodepen />
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  )
}

export { Portfolio }
