import React from "react"

import { Container, Title } from "../shared"

import blogging from "../../assets/image/blogging.svg"
import travelling from "../../assets/image/travelling.svg"
import programming from "../../assets/image/coding.svg"
import movie from "../../assets/image/movie.svg"

import "./interests.css"

const Interests = () => {
  return (
    <div className="interests-area">
      <Container>
        <Title side="left" title="Interests" style={{marginTop: '1% !important'}} />
        <div className="interests">
        <div className="stickpad">
            <img alt="" src={programming} />
            <h4>Programming</h4>
          </div>
          <div className="stickpad">
            <img alt="" src={travelling} />
            <h4>Travelling</h4>
          </div>
          
          <div className="stickpad">
            <img alt="" src={blogging} />
            <h4>Blogging</h4>
          </div>
          <div className="stickpad">
            <img alt="" src={movie} />
            <h4>Historical Movie</h4>
          </div>
        </div>
      </Container>
    </div>
  )
}

export { Interests }
